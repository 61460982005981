
import React, { useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

import {
    Modal
} from 'react-bootstrap';

/**
 * renders a button that opens a popup where user can choose 
 * their campus and old/new student
 */

export default function SecondaryRegistrationButton(props) {

    const [showModal, setShowModal] = useState(false);

    return (
        <React.Fragment>

            <a className="btn btn-primary b-btn-primary b-btn-landing"
                onClick={() => setShowModal(true)} >
                Senior High School (Grades 11 &#38; 12)
            </a>

            <SHSCampusModal
                show={showModal}
                handleClose={() => setShowModal(false)}
                baseUrl={props.baseUrl}
                campuses={props.campuses} />

        </React.Fragment>
    );

}

/**
 * Modal to let the user choose which campus, and if they are New or Old students
 */
const SHSCampusModal = props => {

    return (
        <Modal
            size="lg"
            show={props.show}
            onHide={props.handleClose}
            centered >

            <Modal.Body className="text-center">

                <FontAwesomeIcon
                    className="b-modal-close"
                    onClick={props.handleClose}
                    icon={faTimes} />

                <div className="row p-4 b-text-secondary school-popup">
                    {props.campuses.map(function (el, ind) {
                        const collegeNames = el.campus.split(" ");
                        const campus = collegeNames.pop();

                        return (
                            <div 
                                key={`college-${ind}`}
                                className="col-12 col-sm-6 text-center py-4">
                                <h3 className="mb-4"> {collegeNames.join(" ")} <br /> {campus} </h3>
                                <a className="btn btn-primary b-btn-primary m-2"
                                    href={`${props.baseUrl}${el.tag}`} >
                                    Register
                                </a>
                            </div>
                        )
                    })}
                </div>

            </Modal.Body>
        </Modal>
    );

}

