
import React from 'react';

/**
 * renders a button that opens a popup where user can choose 
 * their campus and old/new student
 */

export default function PrimaryRegistrationButton(props) {

    return (
        <React.Fragment>

            {props.schools.map((sch, index) => {
                return (
                    <a key={`registration-options-${index}`}
                        className="btn btn-primary b-btn-primary b-btn-landing"
                        href={`${props.baseUrl}${sch.tag}`} >
                        {sch.name}
                    </a>
                )
            })}

        </React.Fragment>
    );

}

