
import ReactDOM from 'react-dom';

import React from 'react';

import EnrollmentCloseModal from '../components/registration-options/enrollment-close-modal';

import PrimaryRegistrationButton from '../components/registration-options/primary-button';
import JuniorRegistrationButton from '../components/registration-options/junior-button';
import SecondaryRegistrationButton from '../components/registration-options/secondary-button';
import TertiaryRegistrationButton from '../components/registration-options/tertiary-button';

const RegistrationOptions = props => {

    const {
        primary, junior, secondary, tertiary
    } = props.schools;

    const baseUrl = "/registration_requests/new?school=";
    const [enrollmentCloseModal, setEnrollmentCloseModal] = React.useState({
        show: false,
        school: null,
        schoolYear: null
    });
 
    return (
        <React.Fragment>
    
            <EnrollmentCloseModal
                show={enrollmentCloseModal.show}
                handleClose={() => setEnrollmentCloseModal({ ...enrollmentCloseModal, show: false })}
                school={enrollmentCloseModal.school}
                schoolYear={enrollmentCloseModal.schoolYear} />
            
            <div className="s-btn-wrapper">
                { primary != null && 
                    <PrimaryRegistrationButton 
                        baseUrl={baseUrl}
                        schools={primary} />
                }

                { junior != null &&
                    <JuniorRegistrationButton 
                        baseUrl={baseUrl}
                        campuses={junior} />
                }

                { secondary != null &&
                    <SecondaryRegistrationButton 
                        baseUrl={baseUrl}
                        campuses={secondary} />
                }

                { tertiary != null && 
                    <TertiaryRegistrationButton
                        baseUrl={baseUrl}
                        colleges={tertiary} />
                }
            </div>

        </React.Fragment>
    )
}

/*
 * attaching the component to the page.
 */
document.addEventListener('DOMContentLoaded', function () {
    const root = document.getElementById('btn-registration-options');
    root.innerHTML = "";
    ReactDOM.render(
        <RegistrationOptions
            schools={window.schools} />,
        root
    );
});

