import React from "react";

import { Modal, Button } from "react-bootstrap";

/**
 * controls and design for the Popup that shows enrollment
 * has already closed.
 */

export default function EnrollmentCloseModal(props) {

  const { school, schoolYear } = props;

  if(schoolYear == null) 
    return (<div></div>);

  return (
    <Modal show={props.show} onHide={props.handleClose} centered>
      <Modal.Header className="b-bg-primary text-white">
        <Modal.Title className="mx-auto">Notice</Modal.Title>
      </Modal.Header>

      <Modal.Body className="text-center">
        <p className="pt-4">
          Enrollment 
          {school != null && <React.Fragment> for <span className="font-weight-bold"> {school.name}</span></React.Fragment> }
          <span> is currently</span>
          <span className="font-weight-bold"> CLOSED </span> for the
          <span className="font-weight-bold"> {schoolYear.semester} of SY {schoolYear.code}</span>.
          For Enrollment Schedules, please visit our website at 
          <a href="benedictocollege.edu.ph"> benedictocollege.edu.ph </a>
          or follow us on Facebook at benedictocollegeofficial.
        </p>
        <p>
          For inquiries, call us at <br />
          032 345 5790 / 032 345 6873 <br />
          or send us an email at <br />
          <a href="#"> info@benedictocollege.edu.ph </a>
        </p>

        <Button
          className="b-btn-pill b-btn-primary registration-modal-button"
          onClick={props.handleClose}
        >
          Okay
        </Button>
      </Modal.Body>
    </Modal>
  );
}
